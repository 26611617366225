@import "../App.css";

.Footer {
    position: relative;
    width: 100%;
    height: max(min(6.25vw, 12vh), 4rem);
}
@media only screen and (min-width: 800px) {
    .Footer {
        background-repeat: no-repeat;

        -moz-background-attachment: fixed;
        -webkit-background-attachment: fixed;
        -o-background-attachment: fixed;
        background-attachment: fixed;
    
        -webkit-background-size: var(--background-size);
        -moz-background-size: var(--background-size);
        -o-background-size: var(--background-size);
        background-size: var(--background-size);
    }
    
    /* Page Specific Background */
    .Footer#Home {
        background-image: url("../../images/Backgrounds/HomeBackground.jpg");
    }
    .Footer#Work {
        background-image: url("../../images/Backgrounds/WorkBackground.jpg");
    }
    .Footer#Education {
        background-image: url("../../images/Backgrounds/LibraryBackground.jpg");
    }
    .Footer#Portfolio {
        background-image: url("../../images/Backgrounds/PortfolioBackground.jpg");
    }
}
@media only screen and (max-width: 800px) {
    .Footer#Home {
        background-color: #4f67ff;
    }
    .Footer#Work {
        background-color: #2e7598;
    }
    .Footer#Education {
        background-color: #d29e5d;
    }
    .Footer#Portfolio {
        background-color: #06b002;
    }
}

/* General */
.CenterFooter {
    display: flex;
    justify-content: center;
    height: 100%;
}
.CenterFooter#Work {
    background-color: rgb(0, 0, 0, 0.5);
}
.CenterFooter#Education {
    background-color: rgb(0, 0, 0, 0.5);
}

.FooterItems {
    display: flex;
    overflow: hidden;
}

.FooterItem {
    position: relative;
    top: max(1vw, 0.75rem);
    margin-left: 2.5vw;
    margin-right: 2.5vw;

    font-family: var(--title-font);
    font-size: max(min(1.25vw, 55vh), 0.75rem);
    font-weight: 600;
    letter-spacing: max(0.25vw, 2px);
}

/* Page Specific Text*/

.FooterItem#Home {
    color: whitesmoke;
}
.FooterItem#Work {
    color: whitesmoke;
    /* font-family: 'Exo', sans-serif; */
}
.FooterItem#Education {
    color: rgb(231, 231, 231);
}
.FooterItem#Portfolio {
    color: whitesmoke;
}