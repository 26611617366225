@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500;600&family=Montserrat+Alternates:wght@400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Monoton&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aladin&family=Bowlby+One&family=Chivo:wght@300;400;700;900&family=Erica+One&family=Luckiest+Guy&family=Monoton&family=Mrs+Sheppards&family=Nanum+Brush+Script&family=Permanent+Marker&family=Righteous&family=Sedgwick+Ave&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aladin&family=Alfa+Slab+One&family=Luckiest+Guy&family=Monoton&family=Mrs+Sheppards&family=Nanum+Brush+Script&family=Righteous&family=Sedgwick+Ave&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aladin&family=Bowlby+One&family=Chivo:wght@300;400;700;900&family=Erica+One&family=Luckiest+Guy&family=Monoton&family=Mrs+Sheppards&family=Nanum+Brush+Script&family=Permanent+Marker&family=Righteous&family=Sedgwick+Ave&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aladin&family=Bowlby+One&family=Chivo:wght@300;400;700;900&family=Erica+One&family=Luckiest+Guy&family=Monoton&family=Mrs+Sheppards&family=Nanum+Brush+Script&family=Permanent+Marker&family=Righteous&family=Sedgwick+Ave&display=swap");
@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");

:root {
    --title-font: 'Proxima-Nova', sans-serif; /* Montserrat Alternates */
    --text-font: 'proxima-nova', sans-serif;
    --text-font-size: max(min(2vw, 2vh), 1rem);;
    --header-height: 85vh;

    --body-width: min(90vw, 800px);

    /* Home */
    --exhibit-height: max(20vw, 9rem);
    --animation-time: 36s;
    --background-size: max(100vh, 100vw) calc(1.2*var(--header-height));


    --padding-top-bottom-header-image: 25vh;
    --paddy: calc(35vh - 15vw);
    --home-color: rgb(220, 220, 220);
    --counter-color-dark: rgb(50, 153, 146);
    --counter-color: rgb(109, 191, 187);
    --background-color: black;
    --profile-image-height: 17.5vw;
    --name-box-border-radius: 7.5vw;
    --image-border: 0.6vw;

    /* Work */
    --header-text-color: #0f62fe;
    --left-pad-text: 4vw;
    --work-font-size: max(min(1.75vw, 1.75vh), 0.8rem);
    --work-line-height: max(2.5vmin, 1.25rem);

    /* Portfolio */
    --portfolio-title-height: min(10vmax, 10rem);
    --height2: calc(var(--header-height) - max(8vw, 4rem) - 2vh);
    --block-height: min(calc(var(--height2)/2 - 2vw), 31.5vw);
    --block-width: calc(1.52*var(--block-height));
    --spacing: 1.5vw;
    --iconHeight: 10vw;
    --portfolio-body-width: min(90vw, 80vh, 70rem);
    --scale: 1.4;
    --quad-radius: 25px;

    /* Tabs */
    --tab-height: max(4vw, 2.3rem);
    --tab-font-size: min(2vmax, 1.75rem);
}