/* GALLERY */

.image-container2#HuggingFace1 {
    margin-left: 16%;
    margin-right: 15%;
    height: 100%;
    width: 60%;
}

img#HF1 {
    height: 100%;
    width: 100%;
}

img#HF1:hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s;
}