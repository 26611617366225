/* GALLERY */

.image-container2#Music1 {
    margin-left: 5%;
    margin-right: 15%;
    height: 100%;
    width: 63%;
}

img#Music1 {
    height: 100%;
    width: 100%;
}

img#Music1:hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s;
}

.image-container#Music2 {
    position: relative;
    bottom: 100%;
    height: 100%;
    width: 26%;
    margin-left: 69%;
}

img#Music2 {
    height: 100%;
    width: 100%;
}

img#Music2:hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s;
}