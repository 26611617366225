.header#Education {
    background-image: url("../images/Backgrounds/LibraryBackground.jpg"); /* https://www.weforum.org/agenda/2021/12/diversity-inclusion-human-library/ */
    background-repeat: no-repeat;

    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
}

.Head#Education {
    position: relative;
    height: var(--header-height);
}

.imageNYU {
    position: relative;
    left:12.5%;
    height: 25vw;
    width: 25vw;
    border: 1vw solid black;
    -ms-transform: translateX(-50%);
    transform: translateX(0%);
}

.imageNEU {
    position: relative;
    left:15%;
    height: 30vw;
    width: 30vw;
    -ms-transform: translateX(-50%);
    transform: translateX(0%);
}

.imageLFNY {
    position: relative;
    left:20%;
    height: 25vw;
    width: 25vw;
    -ms-transform: translateX(-50%);
    transform: translateX(0%);
}

h1#Education {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    background-color: transparent;

    font-family: var(--text-font);
    font-size: max(13vw, 4rem);
    font-weight: 700;
    color: rgb(255, 243, 220);

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

h2#NYU {
    font-size: 9.6vw;
    line-height: 1vw;
}

h2#NYU2 {
    font-size: 9vw;
    line-height: 1vw;
}

h2#NEU {
    font-size: 7vw;
    line-height: 0vw;
}

h2#NEU2 {
    font-size: 9.1vw;
    line-height: 0vw;
}

.Education {
    padding-left: 5vw;
    background-image: url(https://www.toptal.com/designers/subtlepatterns/uploads/concrete_seamless.png); /* https://www.toptal.com/designers/subtlepatterns/uploads/white_wall_hash.png */
    background-repeat:repeat;
}

.JoinNYU {
    height: 60vw;
}

.JoinNEU {
    height: 60vw;
}

.JoinLFNY {
    height: 60vw;
}

.NYU {
    float: left;
    overflow: hidden;
    height: 100%;
    width: 50%;
}

.Description#NYU {
    padding-top: 4vw;
    height: 100%;
    overflow: hidden;
    font-size: 1.25vw;
    width: 50%;
    text-align: justify;
}

.Description#NEU {
    padding-top: 2vw;
    height: 100%;
    overflow: hidden;
    font-size: 1.25vw;
    width: 50%;
    text-align: justify;
}

.Description#LFNY {
    padding-top: 2vw;
    height: 100%;
    overflow: hidden;
    font-size: 1.25vw;
    width: 50%;
    text-align: justify;
}

.Title {
    padding-bottom: 2vw;
}

#Level {
    text-decoration: overline;
    float: left;
    font-size: 1.5vw;
    font-weight: bold;
}

#Level2 {
    float: left;
    font-size: 1.25vw;
    position: relative;
    top: -2.75vw;
}

#Period {
    float: right;
    padding-right: 6vw;
    font-size: 1.5vw;
}

#Location {
    position: relative;
    float: right;
    top: -2.5vw;
    right: 6vw;
}

#Location2 {
    position: relative;
    float: right;
    top: -2.75vw;
    right: 6vw;
}

.NEU {
    float: right;
    overflow: hidden;
    height: 100%;
    width: 50%;
}

.LFNY {
    float: left;
    overflow: hidden;
    height: 100%;
    width: 50%;
}

h2#LFNY {
    font-size: 6.2vw;
    line-height: 0vw;
}

h2#LFNY2 {
    font-size: 7.4vw;
    line-height: 0vw;
}

#Desc1 {
    position: relative;
    float: left;
    left:0;
    top: -4vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc1Low {
    position: relative;
    float: left;
    left:0;
    top: -5vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc1Lowerish {
    position: relative;
    float: left;
    left:0;
    top: -6vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc1Lower {
    position: relative;
    float: left;
    left:0;
    top: -8vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc2 {
    position: relative;
    float: left;
    left:0;
    top: -3vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc2Low {
    position: relative;
    float: left;
    left:0;
    top: -4vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc2Lower {
    position: relative;
    float: left;
    width: 100%;
    left:0;
    top: -5vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc2Lowest {
    position: relative;
    float: left;
    left:0;
    top: -8vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc3 {
    position: relative;
    float: left;
    left:0;
    top: -4vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}

#Desc3Low {
    position: relative;
    float: left;
    left:0;
    top: -5vw;
    right: 6vw;
    padding-right: 6vw;
    line-height: 2vw;
}