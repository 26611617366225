@import "../App+/App.css";

.DISCLAIMERbig {
    position: relative;
    width: 100%;
    background-color: rgb(255, 0, 0);
    text-align: center;
}

.DISCLAIMERsmall {
    font-size: 2vh;
    font-weight: 200;
    line-height: min(3.5vh, 3.5rem);
    color: whitesmoke;
    font-family: var(--title-font);
}