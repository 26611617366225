@import "../App+/App.css";

.header#Portfolio {
    background-image: url("../images/Backgrounds/PortfolioBackground.jpg");
    background-repeat: no-repeat;

    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
}

.Head#Portfolio {
    position: relative;
    height: var(--header-height);
}

h1#Portfolio {
    height: var(--portfolio-title-height);
    margin: 0;
    width: 100%;
    background-color: transparent;

    text-align: center;
    font-family: 'Monoton', cursive;
    font-size: max(6vw, 3rem);
    font-weight: 500;
    color: black;
}

.PortfolioHeader {
    position: relative;
    width: 100%;
    height: var(--header-height);
}

.Blocks {
    position: relative;
    height: calc(var(--header-height) - var(--portfolio-title-height));
    top: max(-1vw, -1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Top {
    margin-top: var(--spacing);
    display: flex;
    justify-content: center;
}

.Bottom {
    margin-top: var(--spacing);
    margin-bottom: var(--spacing);
    display: flex;
    justify-content: center;
}

/* Quadrants */
.Q1 {
     border-top-left-radius: var(--quad-radius);}
.Q2 {
     border-top-right-radius: var(--quad-radius);}
.Q3 {
     border-bottom-left-radius: var(--quad-radius);}
.Q4 {
     border-bottom-right-radius: var(--quad-radius);}

.Q1, .Q2, .Q3, .Q4 {
    position: relative;
    margin-right: calc(var(--spacing)/2);
    margin-left: calc(var(--spacing)/2);
    width: var(--block-width);
    height: var(--block-height);
    background-color: transparent;
    font-weight: 600;
    color: white;
    transition: 0.3s;
    border: solid black 1px;
}

.BlockTop {
    visibility: hidden;
    opacity: 0;
    color: rgb(226, 226, 226);

    position: absolute;
    top: 0;
    width: 100%;
    height: 3vw;
    background-color: rgb(0, 0, 0, 0.8);
    text-align: center;
    transition: 0.5s;
}

.BlockTop#q1 {border-top-left-radius: var(--quad-radius);}
.BlockTop#q2 {border-top-right-radius: var(--quad-radius);}

#DS-Box-Title {
    position: relative;
    top: -1vw;
    font-family: var(--title-font);
    font-size: 1.5vw;
    font-weight: 800;
    letter-spacing: 2px;
}
#DS-Box-Text {
    visibility: hidden;
    opacity: 0;

    position: relative;
    top: calc(3.5vw - var(--block-height));
    color: black;
    font-size: 0.8vw;
    line-height: 2.5vw;
}

.Q1:hover .BlockTop, .Q2:hover .BlockTop, .Q3:hover .BlockTop, .Q4:hover .BlockTop {
    visibility: visible;
    opacity: 1;
}
.Q1:hover .TitleText1, .Q2:hover .TitleText2, .Q3:hover .TitleText3, .Q4:hover .TitleText4 {
    opacity: 1;
    visibility: hidden;
}
.Q1:hover #DS-Box-Text, .Q2:hover #DS-Box-Text, .Q3:hover #DS-Box-Text, .Q4:hover #DS-Box-Text {
    visibility: visible;
    opacity: 1;
}

.Q1:hover, .Q2:hover, .Q3:hover, .Q4:hover {
    transform: scale(1.025, 1.025);
    background-color: rgba(255, 255, 255, 0.4);
}

.TitleText1, .TitleText2, .TitleText3, .TitleText4  {
    height: 100%;
    width: 100%;
    -webkit-filter: contrast(0) sepia(100%) hue-rotate(190deg) saturate(2000%) brightness(0%);
    filter: contrast(0) sepia(100%) hue-rotate(190deg) saturate(2000%) brightness(0%);
    opacity: 1;
    
    background-size: cover; /* Ensures the image covers the div */
    background-position: center; /* Centers the image */
    background-clip: border-box; /* Clips the background to the border box */
    border-radius: inherit; /* Inherits the border-radius from the parent */
}
/* 
.TitleText1 {

}
.TitleText2 {border-top-right-radius: var(--quad-radius);}
.TitleText3 {border-bottom-left-radius: var(--quad-radius);}
.TitleText4 {border-bottom-right-radius: var(--quad-radius);} */

.Image {
    position: absolute;
    z-index: 1;
    top: calc(20vw - var(--iconHeight)/2);
    left: calc(49.7vw - var(--iconHeight)/2);
    height: var(--iconHeight);
    width: var(--iconHeight);
    background-image: var(--image);
    border: 5px solid black;
    border-radius: 100%;
}

.Show {
    position: relative;
    width: 80%;
    left: 10%;
    transition: 0.5;
}

/* Body */
.PortfolioBody {
    /* z-index: -2; */
    margin-left: calc(50vw - var(--portfolio-body-width)/2);
    margin-right: calc(50vw - var(--portfolio-body-width)/2);
    text-align: justify;
}

h2.Section {
    font-family: var(--title-font);
    font-size: max(min(4vw, 4vh), 1.75rem);
    font-weight: 900;
    margin-top: 8vh;
    padding-top: 0.5vh;
    margin-bottom: 2vh;
    border-top: 0.5vh solid black;
}
@media only screen and (min-width: 800px) {
    #DataScience, #ComputerScience, #MechE, #Random {
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
        background-image: url("../images/Backgrounds/PortfolioBackground.jpg");
        background-repeat: no-repeat;
    
        -moz-background-attachment: fixed;
        -webkit-background-attachment: fixed;
        -o-background-attachment: fixed;
        background-attachment: fixed;
    
        -webkit-background-size: var(--background-size);
        -moz-background-size: var(--background-size);
        -o-background-size: var(--background-size);
        background-size: var(--background-size);
    }
}
@media only screen and (max-width: 800px) {
    #DataScience, #ComputerScience, #MechE, #Random {
        color: black;
    }
}

div.TBC{
    width: 100%;
    text-align: center;
}

h2.TBC {
    font-family: var(--text-font);
    font-size: max(min(3vw, 3vh), 1.25rem);
    font-weight: 800;
    margin-top: 3vh;
    margin-bottom: 0%;
    color: rgb(42, 42, 42);
    margin-bottom: 6vh;
    padding-top: 3vh;
    border-top: 0.5vh solid black;
}

h3.Section {
    font-family: var(--title-font);
    font-size: 2.75vh;
    font-weight: 800;
    margin-bottom: 2vh;
    color: rgb(58, 58, 58);
}

p.Section {
    color: rgb(51, 51, 51);
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.58;
    padding: 0 0 1.5em;
}

.Project {
    margin-bottom: 8vh;
}

.NLPPosition {
    -ms-transform: translateX(-50%);
    transform: translateX(0%);
}

.NLPPDF {
    margin-left: calc(0.1*var(--portfolio-body-width));
    margin-right: calc(0.1*var(--portfolio-body-width));
    width: calc(0.8*var(--portfolio-body-width));
    height: calc(1.3*0.8*var(--portfolio-body-width));
    max-height: 65rem;
}

/* GALLERY */

.image-container {
    width: 100%;
    height: 500px;
    cursor: pointer;
    overflow: hidden;
}

.image-container2 {
    width: 100%;
    height: 375px;
    cursor: pointer;
    overflow: hidden;
}

.Gallery {
    height: calc(var(--portfolio-body-width)/2);
    width: var(--portfolio-body-width);
}

.Gallery1 {
    height: calc(2*var(--portfolio-body-width)/8);
    width: var(--portfolio-body-width);
}

.Gallery2 {
    height: calc(3*var(--portfolio-body-width)/8);
    width: var(--portfolio-body-width);
}

.Gallery3 {
    height: calc(3.25*var(--portfolio-body-width)/8);
    width: var(--portfolio-body-width);
}

.TEDVideo {
    margin-left: 3%;
    width: 94%;
    height: calc(0.56*var(--portfolio-body-width)*0.94)
}