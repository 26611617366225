@import "../App+/App.css";

.header#Work {
    background-image: url("../images/Backgrounds/WorkBackground.jpg");
    background-repeat: no-repeat;

    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
}
@media only screen and (max-width: 1000px) {
    #WorkText {
        width: 93%;
    }
}

.Head#Work {
    position: relative;
    height: var(--header-height);
}

h1#Work {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;

    font-family: var(--title-font);
    font-size: max(13vw, 6rem);
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0 0 max(0.25rem, 0.75vw)  rgba(0, 0, 0, 0.626);

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.COLUMBIA {
    height: 100vh;
    background-color: #1f3c72;
}
.imageCOL {
    padding-top: 4vw;
    padding-left: var(--left-pad-text);
    width: 35vw;
}

.IBM {
    height: 100vh;
    background-color: #0f62fe;
}

.imageIBM {
    padding-top: 4vw;
    padding-left: var(--left-pad-text);
    width: 20vw;
}

.BROCC {
    height: 100vh;
    background-color: black;
}

.imageBROCC {
    padding-top: 4vw;
    padding-left: var(--left-pad-text);
    width: 30vw;
}

.ANALOGIC {
    height: 100vh;
    background-color: rgb(26, 108, 185);
}

.imageANALOGIC {
    padding-top: 4vw;
    padding-left: var(--left-pad-text);
    width: 25vw;
}

.PS6 {
    height: 100vh;
    background-color: #dc2728;
}

.imagePS6 {
    padding-top: 4vw;
    padding-left: var(--left-pad-text);
    width: 25vw;
}

.WorkFlex {
    width: 100%;
    display: flex;
}

/* Text */
.WorkText {
    margin-top: 1vw;
    margin-left: var(--left-pad-text);
    margin-right: var(--left-pad-text);
    text-align: justify;

    font-family: var(--text-font);
    font-size: var(--work-font-size);
    font-weight: 400;
    line-height: var(--work-line-height);
}
@media only screen and (max-width: 1000px) {
    #WorkText {
        width: 93%;
    }
}

#COLText1 {
    padding-top: 4vw;
    color: white;
}
@media only screen and (max-width: 1000px) {
    #ColText1 {
        width: 93%;
    }
}

a:visited#COLText {
    color: rgb(183, 213, 255); /* Change color for visited links */
}

#IBMText1 {
    color: white;
}
@media only screen and (max-width: 1000px) {
    #IBMText1 {
        width: 93%;
    }
}

#BroccText1 {
    color: white;
}
@media only screen and (max-width: 1000px) {
    #BroccText1 {
        width: 93%;
    }
}

#AnalogicText1 {
    color: white;
}
@media only screen and (max-width: 1000px) {
    #AnalogicText1 {
        width: 93%;
    }
}

#PS6Text1 {
    color: black;
}
@media only screen and (max-width: 1000px) {
    #PS6Text1 {
        width: 93%;
    }
}

/* Supporting Images */
.WorkImage {
    margin-top: 1vw;
    margin-right: var(--left-pad-text);
    width: calc(93%/3);
    height: calc(93%/3);
    border-radius: 2vw;
}
@media only screen and (max-width: 1000px) {
    .WorkImage {
        margin: 0;
        width: 0%;
    }
}