@import "../App.css";

.CenterTabs {
    width: 100%;
    display: flex;
    justify-content: center;
}
.CenterTabs#Work {
    background-color: rgba(255, 255, 255, 0.5);
}
.CenterTabs#Education {
    background-color: rgb(0, 0, 0, 0.5);
}

.Tabs {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: var(--tab-height);
}

.TabSpacer {
    margin-top: calc(var(--tab-height)/2 - min(2vmax, 1.75rem)/1.8);
    text-align: center;
}

.TabSpacer#t1 {
    width: calc(4*var(--tab-font-size) + 5vw);
}
.TabSpacer#t2 {
    width: calc(3.5*var(--tab-font-size) + 5vw);
}
.TabSpacer#t3 {
    width: calc(6.5*var(--tab-font-size) + 5vw);
}
.TabSpacer#t4 {
    width: calc(5.5*var(--tab-font-size) + 5vw);
}

@media only screen and (max-width: 500px) {
    .TabSpacer#t1 {
        width: calc(4*var(--tab-font-size) + 2.5vw);
    }
    .TabSpacer#t2 {
        width: calc(3.5*var(--tab-font-size) + 2.5vw);
    }
    .TabSpacer#t3 {
        width: calc(6.5*var(--tab-font-size) + 2.5vw);
    }
    .TabSpacer#t4 {
        width: calc(5.5*var(--tab-font-size) + 2.5vw);
    }
}

.Tab {
    font-family: var(--title-font);
    font-size: min(2vmax, 1.75rem);
    letter-spacing: max(0.25vw, 2px);

    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

p#pText {
    margin: 0;
}

/* Page Specific Text */
.Tab#Home {
    color: whitesmoke;
    font-weight: 400;
}
.Tab#Home:hover {
    text-shadow: 0 0 3px whitesmoke;
    /* font-weight: 800; */
}

.Tab#Work {
    color: black;
    font-weight: 400;
    
}
.Tab#Work:hover {
    text-shadow: 0 0 2px rgb(0, 0, 0);
    /* font-weight: 800; */
}

.Tab#Education {
    color: whitesmoke;
    font-weight: 400;
}
.Tab#Education:hover {
    text-shadow: 0 0 3px whitesmoke;
    /* font-weight: 800; */
}

.Tab#Portfolio {
    color: black;
    font-weight: 400;
}
.Tab#Portfolio:hover {
    text-shadow: 0 0 2px black;
    /* font-weight: 800; */
}
