@import "../App+/App.css";

.header#Home {
    background-image: url("../images/Backgrounds/HomeBackground.jpg");
    background-repeat: no-repeat;

    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
}

.Head#Home {
    position: relative;
    height: var(--header-height);
}

.HeadContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Name {
    height: var(--header-height);
    display: flex;
    align-items: center;

    animation: NameFade;
    animation-duration: calc(var(--animation-time)/9);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

h4.FirstName {
    margin: 0;
    margin-bottom: 15%;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 10vw;
    font-weight: 900;
    color: whitesmoke;
}

h4.LastName {
    margin: 0;
    margin-bottom: -5%;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 10vw;
    font-weight: 900;
    color: whitesmoke;
}

.ExhibitImgDiv {
    margin-top: calc(var(--header-height)/2 - 10vw);
    border-top-left-radius: 50%;
    border-bottom-left-radius: calc(var(--exhibit-height)/16);
    border: solid white 2px;
    height: var(--exhibit-height);
    width: var(--exhibit-height);
    background: rgb(255, 255, 255, 0.9);
    overflow: hidden;
}

.ExhibitImg {
    height: 100%;
    width: 100%;
}

.ExhibitTextAll {
    margin-top: calc(var(--header-height)/2 - 10vw);
    background-color: rgba(245, 245, 245);
    border: solid white 2px;
    width: calc(1.5*var(--exhibit-height));
    height: var(--exhibit-height);
    border-bottom-right-radius: calc(var(--exhibit-height)/2);
    border-top-right-radius: calc(var(--exhibit-height)/16);
    overflow: hidden;
}

.ExhibitText {
    width: 100%;
    height: 80%;
}

h5#ExhibitText {
    margin: 0;
    margin-left: 1vw;
    margin-bottom: 10px;
    padding-top: 1vw;
    font-family: var(--title-font);
    font-size: max(2vw, 1rem);

    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 1000;
    letter-spacing: 0.04em;
}

p#ExhibitText {
    margin-left: 1vw;
    margin-right: 1vw;
    font-family: var(--text-font);
    font-size: max(1vw, 0.5rem);
    
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.58;
    padding: 0 0 1.5em;
}

.ExhibitButton{
    height: 20%;
    width: 100%;
    background-color: rgb(200, 200, 200);
    display: flex;
    align-items: center;
}

/* .ExhibitButton:before,
.ExhibitButton:after {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.ExhibitButton:before {
    top: 0%;
    display: block;
    height: 100%;
    width: 100%;
    content: "";
}

.ExhibitButton:hover:before,
.current .ExhibitButton:before {
    opacity: 0.5;
    background-color: rgba(50, 50, 50, 0.498);
} */

#ExhibitButton{
    position: absolute;
    padding-left: 1vw;
    font-family: var(--text-font);
    font-size: 1.5vw;
    font-weight: 600;
    color: whitesmoke;
}

/* BODY */

.Home#Home {
    background-image: url("../images/Backgrounds/HomeBackground.jpg");
    background-repeat: no-repeat;

    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;

    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
}

.body#Home {
    display: flex;
    justify-content: center;
    align-items: flex;
}

.bodiv {
    width: var(--body-width)
}

h2.Home {
    font-family: var(--title-font);
    font-size: max(min(4vw, 4vh), 1.75rem);
    padding-top: 2vh;
}
@media only screen and (min-width: 800px) {
    #aboutme, #fix {
        /* THESE TWO LINES BELOW ARE POWERFUL */
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;

        background-image: url("../images/Backgrounds/HomeBackground.jpg");
        background-repeat: no-repeat;

        -moz-background-attachment: fixed;
        -webkit-background-attachment: fixed;
        -o-background-attachment: fixed;
        background-attachment: fixed;

        -webkit-background-size: var(--background-size);
        -moz-background-size: var(--background-size);
        -o-background-size: var(--background-size);
        background-size: var(--background-size);
    }
}
@media only screen and (max-width: 800px) {
    #aboutme, #fix {
        color: #4f67ff;
    }
}

h3#Home {
    font-family: var(--text-font);
    font-size: max(min(3vw, 3vh), 1.25rem);
    color: rgb(54, 54, 54);
}

.CenterLeo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LeoImg {
    padding: min(0.75vw, 0.5rem);
    border-radius: 50%;
    width: calc(var(--exhibit-height)/1.25);
}

p#Home {
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    text-align: justify;
    color: rgb(52, 52, 52);

    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.58;
    padding: 0 0 1.5em;
  }

.body#Resume {
    padding-left: calc(50vw - var(--body-width)/2);
    padding-right: calc(50vw - var(--body-width)/2);
}

.ResumePosition {
    -ms-transform: translateX(-50%);
    transform: translateX(0%);
    padding-bottom: 5vh;
}

.ResumePDF, .DiplomaPDF, .HonorsPDF {
    width: var(--body-width);
    max-height: 65rem;
}

.ResumePDF {
    height: calc(1.3*var(--body-width));
}

.DiplomaPDF {
    height: calc(0.725*var(--body-width));
}

.HonorsPDF {
    height: calc(1.3*var(--body-width));
}

.Contact {
    height: 100%;
    width: 100%;
}

.ContactIcons {
    display: flex;
    position: relative;
    justify-content: center;
}

.ContactIcon {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 2vh;
    margin-bottom: 10vh;

    padding: min(0.75vw, 0.5rem);
    border-radius: 50%;
    width: 6.5vh;
}
@media only screen and (min-width: 800px) {
    #fix2 {
        background-image: url("../images/Backgrounds/HomeBackground.jpg");
        background-repeat: no-repeat;

        -moz-background-attachment: fixed;
        -webkit-background-attachment: fixed;
        -o-background-attachment: fixed;
        background-attachment: fixed;

        -webkit-background-size: var(--background-size);
        -moz-background-size: var(--background-size);
        -o-background-size: var(--background-size);
        background-size: var(--background-size);
    }
}
@media only screen and (max-width: 800px) {
    #fix2 {
        background-color: #4f67ff;
    }
}