#variables {
  --scale: 0.15
}

@font-face {
  font-family: 'recreate';
  src: url('fonts/Recreate-KV0aW.ttf') format('truetype');
}

/* @font-face {
  font-family: 'neoris';
  src: url('fonts/TT Neoris Trial Black.ttf') format('truetype');
} */

@font-face {
  font-family: 'serenata';
  src: url('fonts/Serenata.ttf') format('truetype');
}

.cont {
  position: relative;
  height: 95vh;
  width: 100%;
}

.bottom {
  top: 0;             
  left: 0;            
  height: 100vh;
  width: 100%;        
  display: flex;      /* Optional: Enables flexbox properties inside the divs */
  justify-content: center; /* Optional: Horizontally center content */
  align-items: center;     /* Optional: Vertically center content */
}

.top {
  top: 0;             
  left: 0;            
  height: 100vh;
  width: 100%;        
  display: flex;      /* Optional: Enables flexbox properties inside the divs */
  justify-content: center; /* Optional: Horizontally center content */
  align-items: center;     /* Optional: Vertically center content */
  background-color: rgb(171, 3, 10, 0.1);
}

.headerSocial {
}

.top2, .bottom2 {
  position: absolute;
  top: 0;             
  left: 0;            
  /* height: 100vh; */
  width: 100%;        
  display: flex;      /* Optional: Enables flexbox properties inside the divs */
  justify-content: center; /* Optional: Horizontally center content */
}

#live {
  width: 100%;
  height: auto;
  background-image: url('../images/MusicImg/ArtistHeaderExtended.jpg');
  background-size: cover;      /* Makes the image cover the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;/* Prevents tiling */
}
#live::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35); 
  pointer-events: none;          
}

#bio {
  width: 100%;
  height: auto;
  background-image: url('../images/MusicImg/HeadshotExtended.jpg');
  background-size: cover;      /* Makes the image cover the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;/* Prevents tiling */
}
#bio::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  pointer-events: none;          
}

.bottom2 {
  /* box-shadow: inset 0px 0px 5rem black; */
}

.textTop, .textBottom {
  position: absolute;
  top: 0;             /* Align to the top edge of the container */
  left: 0;            /* Align to the left edge of the container */
  height: 100%;
  width: 100%;        /* Stretch to fill the width of the container */
  display: flex;      
  justify-content: center;
  align-items: center;    
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container2 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderName {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderNameInner {
  width: 100%;
}

.HeaderName img {
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}

.HeaderName p {
  position: relative;
  margin: 0;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 12rem;
  line-height: 9.5rem;
  font-weight: light;
  -webkit-text-stroke: 2px white;
  color: white;
  z-index: 2;
}







/* BODY */
.corpus {
  position: relative;
  width: 100%;
}

.contCorpus {
  position: relative;
  width: 100%;
  height: 100vh;
}

.conText {
  height: 100%;
  width: 100%;
}

.black {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(39, 164, 210);
  /* background-color: rgb(166, 81, 81); */
}

.releases {
  width: min(100%, 1200px);
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.releases h1{
  position: relative;
  margin: 0;
  /* font-family: "recreate"; */
  font-family: serenata;
  font-size: 3.5rem;
  line-height: 5rem;
  z-index: 2;
  text-align: center; 
  /* -webkit-text-stroke: 1px rgb(255, 255, 255, 0.5); */

  color: rgba(0, 0, 0, 0.5);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}

#rel {
  font-family: serenata;
  font-size: 2rem;
}

.songs {
  padding-top: 1rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}
#stream:hover{
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.75s;
}



.CoverArt {
  /* position: relative; */
  /* border-top: 1px solid #95663b; */
  /* display: flex;  */
  /* justify-content: center; 
  align-items: center;  */

  border-radius: 2.5%;
  width: 20rem;
}

.divider {
  height: 2px;
  width: 100%;
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  background-attachment: fixed;
  filter: brightness(60%);
}

.divider2 {
  position: relative;
  height: 2px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

#cover {
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#cover:hover {
  filter: none; /* invert(100%); */
  background-color: rgba(0, 0, 0, 0.75);
  /* background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 15%, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 1) 33%, rgba(0, 0, 0, 1) 66%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.6) 85%, rgba(0, 0, 0, 0) 100%); */
  /* background: radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 50%); */
  transition: 0.75s;
}

.song1 {
  position: relative;
  height: 5rem;
  /* border-top: 1px solid #95663b; */

  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.song1:hover {
  filter: grayscale(100%);
  background-color: rgba(0, 0, 0, 0.75);;
  transition: 0.5s;
}

.song2 {
  position: relative;
  height: 5rem;
  /* border-top: 1px solid #95663b; */

  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.song2:hover {
  filter: grayscale(100%);
  background-color: rgba(0, 0, 0, 0.5);;
  transition: 0.5s;
}

.record {
  position: absolute;
  left: 2rem;
  font-family: serenata;
  font-size: 1.125rem;
  line-height: 3rem;
  font-weight: bold;

  color: rgba(0, 0, 0, 0.25);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}
.recordB {
  position: absolute;
  left: 2rem;
  font-family: serenata;
  font-size: 1.25rem;
  line-height: 3rem;

  color: rgb(127 163 176);
}

.PromoteRecord {
  position: relative;
  /* border-top: 1px solid #95663b; */

  justify-content: center; 
  align-items: center; 
  flex-wrap: wrap;
}

.recordTop {
  justify-content: center;
  font-family: serenata;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 1rem;
  margin-left: 0rem;

  color: rgba(0, 0, 0, 0.25);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}
.song1:hover .recordTop {
  color: white;
  transition: 0.75s;
}
.song1:hover .record {
  color: white;
  transition: 0.75s;
}

.recRelease {
  justify-content: center;
  font-family: serenata;
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: bold;
  margin: 1rem;
  margin-left: 0rem;

  color: rgba(0, 0, 0, 0.25);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}
.song1:hover .recRelease {
  color: white;
  transition: 0.75s;
}
.song1:hover .recStat {
  color: white;
  transition: 0.75s;
}

.recMid {
  position: absolute;
  /* right: 2rem; */
  font-family: serenata;
  font-size: 1.25rem;
  line-height: 4rem;

  color: rgba(0, 0, 0, 0.6);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}

.recStat {
  position: absolute;
  right: 2rem;
  font-family: serenata;
  font-size: 1.25rem;
  line-height: 4rem;

  color: rgba(0, 0, 0, 0.6);
  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-repeat: no-repeat;
  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
}
.recStatB {
  position: absolute;
  right: 2rem;
  font-family: serenata;
  font-size: 1.25rem;
  line-height: 4rem;

  color: rgb(127 163 176);
}

.rec2 {
  width: 80%;
  font-family: recreate;
  font-size: 6rem;
  line-height: 6rem;

  background-image: url("../images/MusicImg/Rusty.jpeg"); 
  background-size: cover; 
  background-position: center; 
  -webkit-background-clip: text;
  background-clip: text; 
  color: transparent;
  /* color: rgba(255, 255, 255, 0.75); */
  /* -webkit-text-stroke: 2px rgb(255, 255, 255, 0.75); */
  text-align: center; 
  /* text-shadow: 0 0 10px rgb(0, 0, 0); */
}

.HeaderName2 {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0) 70%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.HeaderName2 img {
  height: 100%;
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 50%;
}




.ContCont {
  height: 100%;
  width: 100%;
  display: flex;          
  justify-content: center; 
  align-items: center;  
  overflow: hidden; 
  /* background-color: black; */
}

.ContCont2 {
  width: 100%;
  display: flex;          
  justify-content: center; 
  align-items: center;  
  overflow: hidden; 
  /* background-color: black; */
}

.Cont {
  position: fixed;
}

.Cont1 {
  /* height: 100%; */
  width: 100%;
  filter: url(#warp1);
}

.Cont2 {
  top: 100vh;
  height: 202vh;
  width: 205vw;
  filter: url(#warp2);
  background-image: url("../images/MusicImg/Rusty.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center; 

  -moz-background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-attachment: fixed;
  position: fixed;
}

.crt {
  position: relative;
  height: 100%;
  width: 100%;
}
.crt2 {
  position: relative;
  height: 100%;
  width: 100%;
}

.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background:
  linear-gradient(rgba(0, 0, 0, 0) 0%, 
    rgba(0, 0, 0, 0) 100%),
  linear-gradient(90deg, 
    rgba(0, 0, 0, 0.5) 0%, 
    rgba(61, 0, 0, 0.25) 15%, 
    rgba(0, 54, 0, 0.25) 50%, 
    rgba(0, 0, 89, 0.25) 85%,
    rgba(0, 0, 0, 0.5) 100%);
  z-index: 2;
  background-size: 100% 4px, 6px 100%;
  pointer-events: none;
}

.crt2::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background:
              linear-gradient(rgba(0, 0, 0, 0) 0%, 
                              /* rgba(0, 0, 0, 0.25) 10%, 
                              rgba(0, 0, 0, 0) 50%, 
                              rgba(0, 0, 0, 0.25) 90%,  */
                              rgba(0, 0, 0, 0) 100%),
              linear-gradient(90deg, 
                              rgba(0, 0, 0, 1) 0%, 
                              rgba(61, 0, 0, 0.25) 30%, 
                              rgba(0, 54, 0, 0.25) 50%, 
                              rgba(0, 0, 89, 0.25) 70%,
                              rgba(0, 0, 0, 1) 100%);
  z-index: 2;
  background-size: 100% 4px, 6px 100%;
  pointer-events: none;
}

.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

.crt2::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

.svg {
  position: absolute;
}

.image__img {
  filter: url(#warp)
}

.vid {
  height: 100%;
  width: 100%;
  background-color: black;
  overflow: hidden;
}

video {
  filter: saturate(200%);
  animation: flicker 0.01s infinite;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

@keyframes flicker {
  0%   { opacity: 0.7; }
  10%   { opacity: 0.65; }
  20%   { opacity: 0.7; }
  100%   { opacity: 0.7; }
}


#fixB {
  a: #882000;
  /* background-color: rgba(127, 163, 176, 0.313); */
}

.SocialIcons {
  display: flex;
  position: relative;
  justify-content: center;
}

.SocialIcon {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  padding: 2px;
  border-radius: 50%;
  width: 2rem;
}

.SocialIcon2 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  padding: 0.25rem;
  border-radius: 50%;
  width: 7.5rem;
}
.SocialIcon2:hover {
  /* width: 8rem; */
  background-color: #882000;
  transition: 1s;
}

/* #transparentBox{
  background-color: rgb(0, 0, 0, 0.25);
} */


/* MOBILE */
@media only screen and (max-width: 1000px) {
  .HeaderName p {
    font-size: 5rem;
    line-height: 4rem;
  }

  .releases {
    padding-top: 5rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .releases h1 {
    font-size: 2rem;
    line-height: 1.5rem;
  }

  .songs {
    padding-top: 5rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .record, .recordB {
    left: 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }

  .recStat, .recStatB {
    right: 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }

  .ContCont2 {
    height: 100vh;
  }

  .Cont2 {
    height: 100%;
    width: 100%;
    filter: none;
    z-index: 0;
  }

  #fixBack {
    background-image: url("../images/MusicImg/Rusty.jpeg");
    background-repeat: no-repeat;
    background-position: center; 
    -moz-background-attachment: fixed;
    -webkit-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;
    -webkit-background-size: var(--background-size);
    -moz-background-size: var(--background-size);
    -o-background-size: var(--background-size);
    background-size: var(--background-size);
  }

  .crt2::before {
    background:
                linear-gradient(rgba(0, 0, 0, 0) 0%, 
                                rgba(0, 0, 0, 0) 100%),
                linear-gradient(90deg, 
                                rgba(0, 0, 0, 0.5) 0%, 
                                rgba(61, 0, 0, 0.25) 15%, 
                                rgba(0, 54, 0, 0.25) 50%, 
                                rgba(0, 0, 89, 0.25) 85%,
                                rgba(0, 0, 0, 0.5) 100%);
    background-size: 100% 4px, 6px 100%;
  }

  .SocialIcon2 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 2px;
    border-radius: 50%;
    width: 3rem;
  }
}


.bioText {
  position: relative;
  left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: serenata;
  font-size: 1.125rem;
  line-height: 2rem;
  font-weight: bold;

  color: white;
}