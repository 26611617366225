/* GALLERY */

.image-container2#Alphabite {
    margin-left: 20%;
    margin-right: 15%;
    height: 100%;
    width: 60%;
    border: 1px solid rgba(0, 0, 0, 0.25) 
}

img#Alphabite {
    height: 100%;
    width: 100%;
}

img#Alphabite:hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s;
}